import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  PieChartOutlined,
  AttachMoney,
  PeopleAlt,
  CategoryOutlined,
  ListAltOutlined,
  SpeedOutlined,
  DataUsageOutlined,
  EngineeringOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/profile.jpg";
import { useSelector } from "react-redux";

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: (
      <span style={{ color: "#F0F0F0", fontWeight: "bold" }}>OPERATIONS</span>
    ),
    icon: null,
    roles: ["Administrateur"],
  },
  {
    text: "Clients",
    icon: <SpeedOutlined />,
    hasSubmenu: true,
    submenuOpen: false,
    submenu: [
      {
        text: "Factures",
        icon: <AttachMoney />,
        roles: ["Administrateur"],
      },
      {
        text: "Propriétaires",
        icon: <PeopleAlt />,
        roles: ["Administrateur"],
      },
      {
        text: "Catégories",
        icon: <ListAltOutlined />,
        roles: ["Administrateur"],
      },
    ],
    roles: ["Administrateur"],
  },
  {
    text: "Ajouter Clients",
    icon: <Groups2Outlined />,
    roles: ["Administrateur"],
  },
  {
    text: "Ajouter Catégorie",
    icon: <CategoryOutlined />,
    roles: ["Administrateur","Utilisateur"],
  },
  {
    text: "Facturation",
    icon: <ReceiptLongOutlined />,
    roles: ["Administrateur","Utilisateur"],
  },
  {
    text: "Ajouter Donnée Technique",
    icon: <DataUsageOutlined />,
    roles: ["Administrateur", "Utilisateur"],
  },
  {
    text: "Ajouter Donnée Production",
    icon: <EngineeringOutlined />,
    roles: ["Administrateur","Utilisateur"],
  },
  {
    text: "Ajouter Charges Financières",
    icon: <PeopleAlt />,
    roles: ["Administrateur"],
  },
  {
    text: "Ajouter Relevés Indicateurs",
    icon: <PeopleAlt />,
    roles: ["Administrateur","Utilisateur"],
  },
  {
    text: "Ajouter Sortie Caisse",
    icon: <PeopleAlt />,
    roles: ["Administrateur"],
  },
  {
    text: "Ajouter Tresorerie Globale",
    icon: <PeopleAlt />,
    roles: ["Administrateur"],
  },
  {
    text: "Ajouter Donnée Bancaire",
    icon: <PeopleAlt />,
    roles: ["Administrateur"],
  },
  {
    text: "Ajouter Transaction Bancaire",
    icon: <PeopleAlt />,
    roles: ["Administrateur"],
  },
  {
    text: "Ajouter Dette",
    icon: <PeopleAlt />,
    roles: ["Administrateur"],
  },
  {
    text: "Ajouter Créance",
    icon: <PeopleAlt />,
    roles: ["Administrateur"],
  },
  {
    text: "Informations",
    icon: <ReceiptLongOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: "Geographie",
    icon: <PublicOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: (
      <span style={{ color: "#F0F0F0", fontWeight: "bold" }}>STATISTIQUES</span>
    ),
    icon: null,
    roles: ["Administrateur"],
  },
  {
    text: "Résumé",
    icon: <PointOfSaleOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: "Index Zero",
    icon: <TodayOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: "Surconsommation",
    icon: <TodayOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: "Journalier",
    icon: <TodayOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: "Mensuel",
    icon: <CalendarMonthOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: "Répartition",
    icon: <PieChartOutlined />,
    roles: ["Administrateur"],
  },
  {
    text: (
      <span style={{ color: "#F0F0F0", fontWeight: "bold" }}>RAPPORTS</span>
    ),
    icon: null,
    roles: ["Administrateur"],
  },
  {
    text: "Gestion",
    icon: <SpeedOutlined />,
    hasSubmenu: true,
    submenuOpen: false,
    submenu: [
      {
        text: "Rapport Gestion",
        icon: <AttachMoney />,
        roles: ["Administrateur"],
      },
      {
        text: "Liste Données Techniques",
        icon: <ReceiptLongOutlined />,
        roles: ["Administrateur"],
      },
      {
        text: "Distribution Eau",
        icon: <AttachMoney />,
        roles: ["Administrateur"],
      },
      {
        text: "Production Eau",
        icon: <PeopleAlt />,
        roles: ["Administrateur"],
      },
      {
        text: "Charges Financières",
        icon: <AttachMoney />,
        roles: ["Administrateur"],
      },
      {
        text: "Relevés Indicateurs",
        icon: <PeopleAlt />,
        roles: ["Administrateur"],
      },
      {
        text: "Infos",
        icon: <PeopleAlt />,
        roles: ["Administrateur"],
      },
      {
        text: "Entrée Caisse",
        icon: <AttachMoney />,
        roles: ["Administrateur"],
      },
      {
        text: "Sortie Caisse",
        icon: <AttachMoney />,
        roles: ["Administrateur"],
      },
      {
        text: "Liste Tresorerie",
        icon: <AttachMoney />,
        roles: ["Administrateur"],
      },
      {
        text: "Situation Caisse",
        icon: <AttachMoney />,
        roles: ["Administrateur"],
      },
    ],
    roles: ["Administrateur"],
  },
];


const routeMap = {
  "Factures": "/factures",
  "Propriétaires": "/proprietaires",
  "Catégories": "/categories",
  "Rapport Gestion": "/rapport-gestion",
  "Liste Données Techniques": "/liste-donnees-techniques",
  "Distribution Eau": "/distribution-eau",
  "Production Eau": "/production-eau",
  "Charges Financières": "/charges-financieres",
  "Relevés Indicateurs": "/releves-indicateurs",
  "Infos": "/infos",
  "Entrée Caisse": "/entree-caisse",
  "Sortie Caisse": "/sortie-caisse",
  "Liste Tresorerie": "/liste-tresorerie",
  "Situation Caisse": "/situation-caisse",
  "Résumé": "/resume",
  "Index Zero": "/index-zero",
  "Surconsommation": "/surconsommation",
  "Journalier": "/journalier",
  "Mensuel": "/mensuel",
  "Répartition": "/repartition",
  "Gestion": "/gestion",
  "Liste Données Techniques": "/liste-donnees-techniques",
  "Distribution Eau": "/distribution-eau",
  "Production Eau": "/production-eau",
  "Charges Financières": "/charges-financieres",
  "Relevés Indicateurs": "/releves-indicateurs",
  "Infos": "/infos", 
  "Entrée Caisse": "/entree-caisse",
  "Sortie Caisse": "/sortie-caisse",
  "Liste Tresorerie": "/liste-tresorerie",
  "Situation Caisse": "/situation-caisse",
};



const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [subMenus, setSubMenus] = useState(
    navItems.map((item) => item.submenuOpen || false)
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const user = useSelector((state) => state.global.user);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const userRole = user ? user.role : null;

  // Filter navItems based on userRole
  const filteredNavItems = navItems.filter(item => !item.roles || item.roles.includes(userRole));

  // Initialize sidebar open state based on screen size
  useEffect(() => {
    if (isLargeScreen) {
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  }, [isLargeScreen, setIsSidebarOpen]);

  // Automatically close sidebar on mobile screens after 7 seconds
  useEffect(() => {
    let timer;
    if (isSidebarOpen && isMobile) {
      timer = setTimeout(() => setIsSidebarOpen(false), 7000);
    }
    return () => clearTimeout(timer);
  }, [isSidebarOpen, isMobile]);

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleSubMenuClick = (index) => {
    setSubMenus(subMenus.map((open, i) => (i === index ? !open : open)));
  };

  const handleSubmenuItemClick = (subItemText) => {
    navigate(routeMap[subItemText] || "/");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box
      component="nav"
      sx={{
        display: "flex",
        width: isSidebarOpen ? drawerWidth : 0,
        transition: "width 0.3s ease",
      }}
    >
      <Drawer
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        variant="persistent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: theme.palette.background.alt,
            borderWidth: isNonMobile ? 0 : "2px",
            color: theme.palette.secondary[200],
            transition: "transform 0.3s ease, opacity 0.3s ease",
            transform: isSidebarOpen ? "translateX(0)" : `translateX(-${drawerWidth}px)`,
            visibility: isSidebarOpen ? "visible" : "hidden",
            opacity: isSidebarOpen ? 1 : 0,
          },
        }}
      >
        <Box width="100%">
          <Box m="1.5rem 2rem 2rem 3rem">
            <FlexBetween color={theme.palette.secondary.main}>
              <Box display="flex" alignItems="center" gap="0.5rem">
                <Typography variant="h4" fontWeight="bold">
                  GESCOPIFOR
                </Typography>
              </Box>
              {!isNonMobile && (
                <IconButton onClick={toggleSidebar}>
                  <ChevronLeft />
                </IconButton>
              )}
            </FlexBetween>
          </Box>
          <List>
            {filteredNavItems.map(({ text, icon, hasSubmenu, submenu }, index) => {
              if (!icon) {
                return (
                  <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                    {text}
                  </Typography>
                );
              }
              const lcText = text.toLowerCase();

              return (
                <div key={text}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        if (hasSubmenu) {
                          handleSubMenuClick(index);
                        } else {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                          if (isMobile) {
                            setIsSidebarOpen(false);
                          }
                        }
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {hasSubmenu && (
                        <ChevronRightOutlined
                          sx={{ ml: "auto", transform: subMenus[index] ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>

                  {hasSubmenu && subMenus[index] && (
                    <List component="div" disablePadding>
                      {submenu
                        .filter(subItem => !subItem.roles || subItem.roles.includes(userRole))
                        .map((subItem) => (
                        <ListItem key={subItem.text} sx={{ pl: "4rem" }}>
                          <ListItemButton
                            onClick={() =>
                              handleSubmenuItemClick(subItem.text)
                            }
                          >
                            <ListItemIcon>{subItem.icon}</ListItemIcon>
                            <ListItemText primary={subItem.text} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </div>
              );
            })}
          </List>
        </Box>

        <Box marginBottom="1.5rem">
          <Divider />
          <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
            <Box
              component="img"
              alt="profile"
              src={profileImage}
              height="40px"
              width="40px"
              borderRadius="50%"
              sx={{ objectFit: "cover" }}
            />
            <Box textAlign="left">
              <Typography
                fontWeight="bold"
                fontSize="0.9rem"
                sx={{ color: theme.palette.secondary[100] }}
              >
                {user ? user.username : "Account"}
              </Typography>
              <Typography
                fontSize="0.8rem"
                sx={{ color: theme.palette.secondary[200] }}
              >
                {user ? user.role : "Role"}
              </Typography>
            </Box>
          </FlexBetween>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;

