import React, { useMemo, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard/dashboard";
import Compteurs from "scenes/compteurs/compteurs";
import Proprietaires from "scenes/compteurs/proprietaires";
import Factures from "scenes/compteurs/factures";
import Clients from "scenes/clients/clients";
import Consommation from "scenes/consommation/consommation";
import Geography from "scenes/geography/index";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Performance from "scenes/performance";
import CategoriePointEeau from "scenes/consommation/categoriePointEeau";
import CategoriesList from "scenes/compteurs/categories";
import DonneeTechniques from "scenes/consommation/donneesTechniques";
import DonneeTechniquesList from "scenes/consommation/donneesTechniquesList";
import ConsommationZero from "scenes/consommation/zeroConsommation";
import ConsommationSuperieure from "scenes/consommation/superieureConsommation";
import RapportGestion from "gestion/rapportGestion";
import DistributionEau from "gestion/distributionEau";
import ProductionEau from "gestion/productionEau";
import ChargesFinancieres from "gestion/chargesFinancieres";
import ChargesFinancieresList from "gestion/chargesFinancieresList";
import EntreeCaisseList from "gestion/entreeCaisseList";
import SortieCaisse from "gestion/sortieCaisse";
import Infos from "gestion/infos";
import InfosList from "gestion/infosList";
import RelevesIndicateurs from "gestion/relevesIndicateurs";
import RelevesIndicateursList from "gestion/relevesIndicateursList";
import DonneesProductions from "scenes/production/donneesProductions";
import Login from "components/Login";
import Register from "components/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./customToastStyles.css";
import { setUser } from "./state/index";
import ProtectedRoute from "./state/ProtectedRoute";
import TresorerieGlobale from "gestion/tresorerieglobale";
import TresorerieGlobaleList from "gestion/tresorerieGlobaleList";
import SortieCaisseList from "gestion/sortieCaisseList";
import SituationCaisse from "gestion/situationCaisse";
import BanqueForm from "gestion/banque";
import TransactionBanqueForm from "gestion/transactionBanqueForm";
import DetteForm from "gestion/dettes";
import CreanceForm from "gestion/creances";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user) {
      dispatch(setUser(JSON.parse(user))); // Load user data into Redux store
    }
  }, [dispatch]);

  return (
    <div className="app">
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/compteurs"
                element={
                  <ProtectedRoute>
                    <Compteurs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/propriétaires"
                element={
                  <ProtectedRoute>
                    <Proprietaires />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/factures"
                element={
                  <ProtectedRoute>
                    <Factures />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter clients"
                element={
                  <ProtectedRoute>
                    <Clients />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/facturation"
                element={
                  <ProtectedRoute>
                    <Consommation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/index zero"
                element={
                  <ProtectedRoute>
                    <ConsommationZero />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/surconsommation"
                element={
                  <ProtectedRoute>
                    <ConsommationSuperieure />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/geographie"
                element={
                  <ProtectedRoute>
                    <Geography />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/résumé"
                element={
                  <ProtectedRoute>
                    <Overview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/journalier"
                element={
                  <ProtectedRoute>
                    <Daily />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mensuel"
                element={
                  <ProtectedRoute>
                    <Monthly />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/répartition"
                element={
                  <ProtectedRoute>
                    <Breakdown />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <Admin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/performance"
                element={
                  <ProtectedRoute>
                    <Performance />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter catégorie"
                element={
                  <ProtectedRoute>
                    <CategoriePointEeau />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/catégories"
                element={
                  <ProtectedRoute>
                    <CategoriesList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter donnée technique"
                element={
                  <ProtectedRoute>
                    <DonneeTechniques />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/liste données techniques"
                element={
                  <ProtectedRoute>
                    <DonneeTechniquesList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter donnée production"
                element={
                  <ProtectedRoute>
                    <DonneesProductions />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/rapport gestion"
                element={
                  <ProtectedRoute>
                    <RapportGestion />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/distribution eau"
                element={
                  <ProtectedRoute>
                    <DistributionEau />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/production eau"
                element={
                  <ProtectedRoute>
                    <ProductionEau />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter charges financières"
                element={
                  <ProtectedRoute>
                    <ChargesFinancieres />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/entrée caisse"
                element={
                  <ProtectedRoute>
                    <EntreeCaisseList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter sortie caisse"
                element={
                  <ProtectedRoute>
                    <SortieCaisse />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter relevés indicateurs"
                element={
                  <ProtectedRoute>
                    <RelevesIndicateurs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/charges financières"
                element={
                  <ProtectedRoute>
                    <ChargesFinancieresList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/relevés indicateurs"
                element={
                  <ProtectedRoute>
                    <RelevesIndicateursList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/infos"
                element={
                  <ProtectedRoute>
                    <InfosList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/informations"
                element={
                  <ProtectedRoute>
                    <Infos />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter tresorerie globale"
                element={
                  <ProtectedRoute>
                    <TresorerieGlobale />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/liste tresorerie"
                element={
                  <ProtectedRoute>
                    <TresorerieGlobaleList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sortie caisse"
                element={
                  <ProtectedRoute>
                    <SortieCaisseList />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/situation caisse"
                element={
                  <ProtectedRoute>
                    <SituationCaisse />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter donnée bancaire"
                element={
                  <ProtectedRoute>
                    <BanqueForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter transaction bancaire"
                element={
                  <ProtectedRoute>
                    <TransactionBanqueForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter dette"
                element={
                  <ProtectedRoute>
                    <DetteForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ajouter créance"
                element={
                  <ProtectedRoute>
                    <CreanceForm />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
